<template lang="pug">
q-dialog(ref="dialog" @hide="query = ''")
    q-card.row.justify-center.q-py-md.full-width(v-if="currentRegion")
        q-btn.absolute-top-right.z-top.q-ma-sm(
            flat
            round
            dense
            v-close-popup)
            q-icon(
                size="xs"
                name="etabl:cross"
                color="grey")
        q-card-section.col-11.q-pb-xs
            p.text-primary.text-h6.text-weight-bold.q-ma-none
                | Выбор города
        q-card-section.col-11.q-mb-sm
            text-field.font-16(
                placeholder="поиск по названию"
                :value="query"
                :disable="loading"
                @input="input"
                @blur="focus = false"
                @focus="focus = true")
            .q-card-section__wrapper.full-width.row.q-py-lg.relative-position(v-show="query === ''")
                .col-6.column.q-px-md
                    p.text-primary.text-subtitle2.text-weight-bold.q-ml-md
                        | Регион
                    q-scroll-area(:thumb-style="{ 'background': 'transparent' }")
                        q-list(dense)
                            template(v-for="(region, key) in locations")
                                q-item(
                                    v-ripple
                                    clickable
                                    active-class="text-positive"
                                    :active="currentRegion.id === key"
                                    @click="currentRegion = region")
                                    q-item-section {{ region.name }}
                q-separator.absolute-center(
                    vertical
                    style="height: 90%;"
                    color="grey-2")
                .col-6.column.q-px-md
                    p.text-primary.text-subtitle2.text-weight-bold.q-ml-md
                        | Населённый пункт
                    q-scroll-area(:thumb-style="{ 'background': 'gray' }")
                        q-list(dense)
                            template(v-for="city in currentRegion.cities")
                                q-item(
                                    v-ripple
                                    clickable
                                    active-class="text-positive"
                                    :active="currentCity?.id === city?.id"
                                    @click="changeCurrentCity(city)")
                                    q-item-section {{ city.name }}
            .q-card-section__wrapper.full-width.row.q-py-lg(v-show="query !== ''")
                .col-12.column.q-px-md
                    q-scroll-area(:thumb-style="{ 'background': 'transparent' }")
                        q-list(v-if="results" dense)
                            template(v-for="item in results")
                                q-item(
                                    v-ripple
                                    clickable
                                    active-class="text-positive"
                                    @click="setLocation(item)")
                                    q-item-section {{ item.id }}
.row.items-center(v-if="currentCity && header && !headerCard")
    .row.font-16(v-if="!userAddress" @click="show") 
        q-icon.q-mt-xs.q-mr-sm(
            name="etabl:marker"
            color="secondary")
        .colum
            .font-13.text-secondary.cursor-pointer Населенный пункт
            .row
                .text-secondary.cursor-pointer
                    | {{ currentCity.name }}
                .text-secondary.text-weight-medium.q-ml-sm.cursor-pointer(v-if="userAuthorized" @click.prevent.stop="$emit('showDeliveryAddress')") Уточнить адрес
    .row.font-16.items-center(v-if="userAddress && userAuthorized" @click="$emit('showDeliveryAddress')")
        q-icon.q-mt-xs.q-mr-sm.cursor-pointer(
            name="etabl:marker"
            color="secondary")
        .column.q-mx-xs
            .text-secondary.cursor-pointer.font-13(v-if="addresTitle" v-html="addresTitle")
            .text-secondary.cursor-pointer(v-html="modUserAddress")
        q-icon.q-mt-xs.q-ml-sm.cursor-pointer(
            name="etabl:edit-mobile"
            color="secondary"
            size="20px")
.row.items-center.q-mr-lg(v-if="currentCity && basket")
    .font-16.text-positive.cursor-pointer.text-weight-medium(
        @click="show") 
        | {{ currentCity.name }}
    q-icon.q-ml-sm(
        name="etabl:edit"
        color="positive")
.row.items-center(v-if="currentCity && home")
    .text-positive.cursor-pointer.text-weight-medium(
        @click="show") 
        | {{ currentCity.name }}
base-btn.justify-center.items-center(
    v-if="button"
    label="Нет, другой"
    loading_color="positive"
    text_color="positive"
    color="secondary"
    height="30"
    font_size="13"
    width="120"
    @click="show"  
    bradius="16"
    border="1px solid #0ca551"
)
h1.text-primary.text-h6.font-24.text-weight-medium.text__title(v-if="currentCity && drugstoreSelect")
    | Аптеки в&nbsp;
    span.text-positive.cursor-pointer(v-if="blackList[currentCity.name]" @click="show")
        | {{ blackList[currentCity.name].value }}
    span.text-positive.cursor-pointer(v-else @click="show")
        | {{ cityIn(currentCity.name) }}
.row.items-center.q-mb-md.text-positive.cursor-pointer.text-weight-medium.justify-between(v-if="headerCard")
    .row.items-center
        q-icon.q-mr-sm(
            @click="show"
            name="etabl:location-marker"
            color="positive"
            size="24px")
        .text-primary.cursor-pointer.line-height-24(
            :class="modalCard ? 'font-18 text-weight-regular' : 'text-weight-bold'"
            @click="show") 
            | Ваш населенный пункт {{ currentCity.name }}
        .q-ml-lg.cursor-pointer(@click="show" :class="modalCard ? 'font-16' : '  '") Изменить
    .font-16.line-height-24.text-positive.cursor-pointer(@click="$emit('dropAddress')" v-if="modalCard")
        | Искать в населенном пункте
</template>

<script>
import { sortCity } from 'assets/helpers/locations.js';
import { Search } from 'assets/helpers/minisearch.js';
import { cityIn } from 'lvovich';
import { useQuasar, LocalStorage } from 'quasar';
import { ref, computed, watch, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import TextField from 'components/BaseFields/TextField.vue';
import BaseBtn from 'components/Base/BaseBtn.vue';

export default {
    components: {
        TextField,
        BaseBtn,
    },
    props: {
        header: {
            type: Boolean,
            default: () => true,
        },
        home: {
            type: Boolean,
            default: () => false,
        },
        button: {
            type: Boolean,
            default: () => false,
        },
        drugstoreSelect: {
            type: Boolean,
            default: () => false,
        },
        basket: {
            type: Boolean,
            default: () => false,
        },
        headerCard: {
            type: Boolean,
            default: () => false,
        },
        modalCard: {
            type: Boolean,
            default: () => false,
        },
        adressDismissed: {
            type: Boolean,
            default: () => false,
        },
    },
    emits:['showDeliveryAddress'],
    setup(props) {
        const init = ref(false);
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const $q = useQuasar();
        
        const locations = ref({});
        const focus = ref(false);
        const loading = ref(false);
        const search = ref(null);
        const results = ref(null);
        const currentRegion = ref(null);
        const currentCity = ref({
            id: '17c304fd-27c4-11e6-a3e4-001e6784c60c',
            name: 'Белгород',
            slug: 'belgorod',
        });
        const dialog = ref(null);

        const cityName = computed(() => store.getters['cityForm/CITY_NAME']);
        const userAuthorized = computed(() => store.getters['user/USER_AUTHORIZED']);
        const userAddress = computed(() => store.getters['user/USER_ADDRESS']);
        const block = computed(() => store.getters['user/BLOCK']);
        
        const query = ref('');
        watch(() => query.value, (value) => {
            value
                ? results.value = search.value.search(value)
                : results.value = null;
        });
        
        const userCityId = computed(() => store.getters['user/USER_CITY_ID']);
        watch(() => userCityId.value, (value) => {
            if (value) {
                Object.values(locations.value)
                    .forEach((item) => {
                        const city = item.cities
                            ?.find(({ id }) => {
                                return id === userCityId.value;
                            });

                        if (city) {
                            currentRegion.value = item;
                            currentCity.value = city;
                        }
                    });
            }
        });

        const modUserAddress = computed(() => {
            if(userAddress.value.address_representation.indexOf('"Табле.Точка"') > 0) {
                
                return userAddress.value.address_representation.slice(25);
            }
            return userAddress.value.address_representation;
        });

        const addresTitle = computed(() => {
            if(userAddress.value.address_representation.indexOf('"Табле.Точка"') > 0) {
                return userAddress.value.address_representation.slice(0, 24);
            }

            return null;
        });

        const show = () => {
            dialog.value.show();
        };

        const hide = () => {
            dialog.value.hide();
        };

        const input = (value) => {
            query.value = value;
        };

        const changeCurrentCity = (city) => {
            currentCity.value = city;
            setLocation();
        };

        const setLocation = async (location) => {
            store.commit('user/SET_BLOCK');
            if (location) {
                query.value = '';

                currentRegion.value = {
                    region_id: location.regionId,
                    region_name: location.regionName,
                    cities: location.cities,
                };
                currentCity.value = {
                    id: location.id,
                    name: location.name,
                };
            }

            try {
                await store.dispatch('user/UPDATE_USER_CITY_ID', currentCity.value.id);
                if (process.env.CLIENT) {
                    LocalStorage.set('USER_CITY_ID', currentCity.value.id);
                }

                hide();

                if (userAuthorized.value) {
                    // await this.RESET_BASKET();
                }

                let redPath = '';

                if(currentCity.value.slug == 'belgorod') {
                    if(route.params.city != '') {
                        redPath = route.fullPath.replace(`/${route.params.city}/`, '/');
                    } else {
                        redPath = `/${currentCity.value.slug}${route.fullPath}`;
                    }
                    redPath = route.fullPath.replace(`/${route.params.city}/`, '/');
                        
                } else {
                    if(route.params.city != '') {
                        redPath = route.fullPath.replace(`/${route.params.city}/`, `/${currentCity.value.slug}/`);
                    } else {
                        redPath = `/${currentCity.value.slug}${route.fullPath}`;
                    }
                }

                await router.replace({ path: `${redPath}`, query: {...route.query}});

                LocalStorage.remove('hidePlash');

                if (process.env.CLIENT){
                    window.location.reload(true);
                }
            } catch (e) {
                $q.notify({
                    color: 'negative',
                    message: e.message,
                });
            }
        };

        const blackList = ref({
            'Маслова Пристань' : {
                value: 'Масловой Пристани',
            },
            'Белые Берега' : {
                value: 'Белых Берегах',
            },
        });

        onMounted(async () => {
            if (LocalStorage.getItem('USER_CITY_ID') && !userAuthorized.value) {
                store.commit('user/SET_USER_CITY_ID', LocalStorage.getItem('USER_CITY_ID'));
            }
            try {
                const response = await store.dispatch('cityForm/GET_LOCATIONS');

                let routeCity = null;

                if(route.params.city == '') {
                    response.results.forEach((region) => { 
                        region.cities.find((item) => {
                            if(item.slug == 'belgorod') {
                                routeCity = item;
                            }} ); });
                } else {
                    response.results.forEach((region) => { 
                        region.cities.find((item) => {
                            if(item.slug == route.params.city) {
                                routeCity = item;
                            }} ); });
                }

                if(!routeCity) {
                    response.results.forEach((region) => { 
                        region.cities.find((item) => {
                            if(item.slug == 'belgorod') {
                                routeCity = item;
                            }} ); });
                };

                if((routeCity.id != userCityId.value) && !block.value) setLocation(routeCity);

                // setLocation(routeCity);

                if (response?.results?.length) {
                    response.results
                        .forEach((item) => {
                            locations.value[item.id] = item;

                            let region = sortCity(item);

                            const city = region.cities
                                ?.find(({ id }) => {
                                    return id === userCityId.value;
                                });

                            if (city) {
                                currentRegion.value = item;
                                currentCity.value = city;
                            };
                        });

                    search.value = new Search(locations.value);
                }

                init.value = true;
            } catch (error) {
                console.error(error);

                $q.notify({
                    color: 'negative',
                    message: error.message,
                });
            }
        });

        return {
            locations,
            cityName,
            currentRegion,
            userCityId,
            dialog,
            show,
            hide,
            input,
            loading,
            focus,
            setLocation,
            query,
            search,
            results,
            changeCurrentCity,
            currentCity,
            init,
            blackList,
            cityIn,
            userAddress,
            userAuthorized,
            block,
            modUserAddress,
            addresTitle,
        };
    },
};
</script>

<style lang="scss" scoped>
.q-card {
    background: $app-color-background;

    .q-card-section__wrapper {
        height: 400px;
        background: $secondary;
        border-radius: $generic-border-radius;

        .q-scrollarea {
            height: 320px;
        }

        .q-list .q-item {
            border-radius: $generic-border-radius;
        }
    }
}

.q-card::-webkit-scrollbar {
    display: none;
}

.text {
    &__title {
        @media (max-width: 1280px) {
            font-size: 20px !important;
        }
    }
}
</style>
